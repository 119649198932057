import React from "react"
import { Col, Container, Row } from "reactstrap"
const Motto = ({ data }) => {
  return (
    <section className="py-5 bg-primary text-white has-quote">
      <Container className="py-5 index-forward">
        <Row className="text-center">
          <Col lg="7" className="mx-auto">
            <h2 className="mb-4">{data.content}</h2>
            <p className="h6 text-uppercase mb-0">{data.author}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Motto
