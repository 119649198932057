import React from "react"
import BestChoice from "../components/BestChoice"
import Blocks2 from "../components/Blocks2"
import Blocks from "../components/Blocks"
import Hero from "../components/Hero"

import Layout from "../components/Layout"
import Motto from "../components/Motto"

import Newsletter from "../components/Newsletter"
import Partners from "../components/Partners"
import Research from "../components/Research"
import SEO from "../components/SEO"

import data from "../content/about.json"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <Hero about className="bg-light" data={data.hero} />
      <Blocks2 data={data.blocks} />
      <Research data={data.research} />
      <Blocks withBackground data={data.blocksWithBackground} />
      <BestChoice data={data.bestChoice} />
      <Motto data={data.motto} />
      <Newsletter className="pt-5" />

      <Partners className="pt-5 mt-5" />
    </Layout>
  )
}

export default AboutPage
