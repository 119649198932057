import React from "react"
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap"
const Newsletter = (props) => {
  return (
    <section className={props.className ? props.className : "py-5"}>
      <Container className="py-5 text-center">
        <Row>
          <Col lg="7" className="mx-auto">
            <p className="h6 text-uppercase text-primary">Newsletter</p>
            <h2 className="mb-3">
              Stay up to date with useful tips via our newsletter
            </h2>
            <p className="text-muted mb-5">
              Lorem ipsum dolor sit amet, consetetur sadipscing elite.
            </p>
            <Form className="p-2 bg-white rounded shadow-sm">
              <InputGroup className="p-1">
                <Input
                  className="border-0 shadow-0"
                  type="email"
                  placeholder="Enter your email address"
                />
                <InputGroupAddon addonType="append">
                  <Button color="primary" className="rounded" type="submit">
                    Subscribe
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Newsletter
