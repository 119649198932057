import React from "react"
import { Col, Container, Row, Media } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Icon from "./Icon"
const Blocks = ({ data, withBackground }) => {
  return (
    <section
      className={
        withBackground ? "py-5 bg-primary text-white with-pattern-full" : "pb-5"
      }
    >
      <Container className={withBackground ? "py-5" : "pb-5"}>
        <Row className={withBackground ? "py-4" : ""}>
          {data.map((item) => (
            <Col
              lg="4"
              className={`mb-lg-0 ${withBackground ? "mb-4" : "mb-3"}`}
              key={item.name}
            >
              <Media className={withBackground ? "align-items-center" : ""}>
                <div
                  className={`icon-block ${
                    item.icon
                      ? " icon-block-lg bg-white shadow"
                      : "rounded-circle"
                  }`}
                >
                  {item.icon ? (
                    <Icon icon={item.icon} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                </div>
                <Media className="ml-3" body>
                  <h5>{item.name}</h5>
                  <p
                    className={`text-small ${
                      withBackground ? "mb-0" : "text-muted"
                    }`}
                  >
                    {item.content}
                  </p>
                </Media>
              </Media>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Blocks
