import React from "react"
import { Col, Container, Row, Media, CardBody, Card } from "reactstrap"
import Icon from "./Icon"
const Blocks2 = ({ data }) => {
  return (
    <section className="pulled-up-section">
      <Container>
        <Card className="shadow-lg bg-primary text-white with-pattern-full">
          <CardBody className="p-lg-5">
            <Row className="py-4">
              {data.map((item) => (
                <Col lg="4" className="mb-4 mb-lg-0" key={item.name}>
                  <Media>
                    <div className="icon-block bg-white">
                      <Icon
                        icon={item.icon}
                        className="text-primary svg-icon-sm"
                      />
                    </div>
                    <Media className="ml-3" body>
                      <h5 className="mb-2">{item.name}</h5>
                      <p className="text-small">{item.content}</p>
                      <ul className="list-check list-check-white mb-0 text-small font-weight-normal">
                        {item.list.map((listItem) => (
                          <li className="mb-1" key={listItem}>
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    </Media>
                  </Media>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </section>
  )
}

export default Blocks2
