import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Col, Container, Media, Row } from "reactstrap"
import Icon from "./Icon"
const BestChoice = ({ data }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "demo-img-5.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="py-5">
      <Container className="py-5">
        <Row className="align-items-center">
          <Col md="7" className="order-2 order-md-1">
            <p className="text-muted text-uppercase mb-2">{data.subtitle}</p>
            <h2>{data.title}</h2>
            <p className="text-muted py-3">{data.content}</p>
            <Row>
              {data.items.map((item) => (
                <Col md="6" className="mb-4" key={item.name}>
                  <Media>
                    <div className="icon-block">
                      <Icon
                        icon={item.icon}
                        className="text-primary svg-icon-sm"
                      />
                    </div>
                    <Media className="ml-3" body>
                      <h5>{item.name}</h5>
                      <p className="text-small  mb-0">{item.content}</p>
                    </Media>
                  </Media>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg="5" className="order-1 order-md-2 mb-5 mb-md-0">
            <div className="img-gradient img-gradient-right video-block">
              <Img
                fluid={image.file.childImageSharp.fluid}
                alt=""
                className="img-fluid shadow rounded"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BestChoice
