import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Col, Container, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
const Research = ({ data }) => {
  const images = useStaticQuery(graphql`
    query {
      research1: file(relativePath: { eq: "demo-img-3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      research2: file(relativePath: { eq: "demo-img-4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [video, setVideo] = useState(false)

  const toggleVideo = (e, name) => {
    e && e.preventDefault()
    setVideo({ ...video, [name]: !video[name] })
  }
  return (
    <section className="py-5">
      <Container className="py-5">
        <Row className="align-items-center pb-5">
          <Col lg="6" className="order-2 order-lg-1">
            <div className="numeric-block">
              <div className="numeric-block-count">1</div>
              <p className="text-muted text-uppercase mb-2">
                {data[0].subtitle}
              </p>
              <h2>{data[0].title}</h2>
              <p className="text-muted py-3">{data[0].content}</p>
              <Row tag="ul" className="list-check">
                {data[0].list.map((item) => (
                  <Col lg="6" tag="li" key={item}>
                    <p className="mb-2 font-weight-mormal">{item}</p>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col lg="5" className="ml-auto order-1 order-lg-2 mb-5 mb-lg-0">
            <div className="img-gradient img-gradient-right video-block">
              <Img
                fluid={images.research1.childImageSharp.fluid}
                alt=""
                className="img-fluid shadow rounded"
              />
              <a
                className="video-btn"
                href="#"
                onClick={(e) => toggleVideo(e, "video1")}
              >
                <span className="video-btn-icon bg-primary text-white">
                  <FontAwesomeIcon icon={faPlay} />
                </span>
              </a>
            </div>
          </Col>
          <ModalVideo
            channel={data[0].video.type}
            autoplay
            isOpen={video.video1}
            videoId={data[0].video.id}
            onClose={() => toggleVideo("", "video1")}
          />
        </Row>
        <Row className="align-items-center pt-lg-5">
          <Col lg="5" className="mb-5 mb-lg-0">
            <div className="img-gradient img-gradient-right video-block">
              <Img
                fluid={images.research2.childImageSharp.fluid}
                alt=""
                className="img-fluid shadow rounded"
              />
              <a
                className="video-btn"
                href="#"
                onClick={(e) => toggleVideo(e, "video2")}
              >
                <span className="video-btn-icon bg-primary text-white">
                  <FontAwesomeIcon icon={faPlay} />
                </span>
              </a>
            </div>
          </Col>
          <Col lg="6" className="ml-auto">
            <div className="numeric-block">
              <div className="numeric-block-count">2</div>
              <p className="text-muted text-uppercase mb-2">
                {data[1].subtitle}
              </p>
              <h2>{data[1].title}</h2>
              <p className="text-muted py-3">{data[1].content}</p>
              <Row tag="ul" className="list-check">
                {data[1].list.map((item) => (
                  <Col lg="6" tag="li" key={item}>
                    <p className="mb-2 font-weight-mormal">{item}</p>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <ModalVideo
            channel={data[0].video.type}
            autoplay
            isOpen={video.video2}
            videoId={data[0].video.id}
            onClose={() => toggleVideo("", "video2")}
          />
        </Row>
      </Container>
    </section>
  )
}

export default Research
