import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row, Container } from "reactstrap"
import Img from "gatsby-image"

const Partners = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 25) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section className={props.className ? props.className : ""}>
      <Container>
        <Row className="align-items-center justify-content-center">
          {data.allFile.edges.map((image, index) => (
            <Col lg="2" md="3" xs="6" className="py-2 py-lg-0" key={index}>
              <Img
                fluid={image.node.childImageSharp.fluid}
                alt=""
                className="d-block mx-auto partner"
                imgStyle={{ objectFit: "contain", height: 25 }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Partners
